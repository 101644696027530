<template>
    <div class="privacy">
        <HeadOrders :PathPageFrom="$t('Home')" :PathPageCenter="false" :PathPageTo="$t('UsingPolicy')" />
       <div class="container">
        <div class="banner">
            <div class="row align-items-center">
                <div class="col-md-6">
                  <h1 class="title">
                    {{ this.$i18n.locale =='ar' ?
                    `الاطلاع على شروطنا و الاحكام`
                    :
                    `View our terms and conditions`
                    }}
                  </h1>
                  <p>
                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى 
                  </p>
                </div>
                <div class="col-md-6 d-flex justify-content-center">
                    <img src="@/assets/img/global/using.png" alt="UsingPolicy">
                </div>
            </div>
        </div>
        <div v-if="StaticPrivacy.length > 0" v-html="StaticPrivacy[0].value"></div>
       </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
    name: 'Using Privacy Page',
    components: {
        HeadOrders: defineAsyncComponent(() => import('@/components/Global/HeadOrders.vue')),
    },
    data() {
    return {
      StaticPrivacy:[]
    };
  },
  methods:{
    handleStaticAbout(StaticContent){
    StaticContent.forEach((item) => {
    if (item.category == 'terms_of_use_page') {
      this.StaticPrivacy.push({
        id: item.id,
        category: item.category,
        key: item.key,
        value: item.value,
      });
    }
  });
    }
  },
  mounted(){
    this.$store.dispatch('GetStaticContent').then(r => {
          this.handleStaticAbout(this.$store.state.StaticContent);
    });
  }
}
</script>